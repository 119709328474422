<template>
    <div class="layout-list-content">
        <ul v-if="me" class="layout-list nopadding nomargin">
             <li class="layout-list-item">
                <a href="#" :class="`layout-list-item-btn pl16 pr24 ${selected === 'Tutti' ? 'selected' : ''}`" @click.prevent="()=>roleFilter('Tutti')">
                    <div class="layout-list-icon admin-icon"></div>
                    <span class="pl8">Tutti</span>
                </a>
            </li>
            <li v-for="(role, index) in roles" :key="role+'-'+index" class="layout-list-item">
                <a href="#" :class="`layout-list-item-btn pl16 pr24 ${role === selected ? 'selected' : ''}`" @click.prevent="()=>roleFilter(role)">
                    <div :class="iconClassName(role)"></div>
                    <span class="pl8">{{role !== 'Concessionaire' ? role : 'Concessionaria'}}</span>
                </a>
            </li>
           
            
        </ul>
    </div>
</template>
<script>
import {computed, inject, ref} from 'vue'
import {useStore} from 'vuex'
import{
  USER_ROLE_SUPER,
  USER_ROLE_ADMIN,
  USER_ROLE_ADVERTISER,
  USER_ROLE_CONCESSIONAIRE_ADMIN,
  USER_ROLE_CONCESSIONAIRE_USER,
  USER_ROLE_PUBLISHER_ADMIN,
  USER_ROLE_PUBLISHER_USER,
  USER_SCOPE_PUBLISHER_DISCRIMINATOR,
  USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR
} from '@/utils/RolesAndPolicies'

export default {
    props:{
        filterPath:{
            type:String,
            default:'filter'
        }
    },
    setup(props){
        const store = useStore()
        const me = inject('me')
        const selected = ref('Tutti')

        const roleFilter =(role)=>{
            selected.value = role
           
            const filter = {
                type:'discriminator',
                value:role
              }
            store.dispatch(props.filterPath, filter)  
        }
        const roles = computed(()=> {
            let toReturn
            switch(me.value.roles[0]){
                case USER_ROLE_SUPER:
                case USER_ROLE_ADMIN:
                    toReturn = [USER_SCOPE_PUBLISHER_DISCRIMINATOR,USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR]
                    break;
                case USER_ROLE_CONCESSIONAIRE_ADMIN:
                    toReturn = [USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR, USER_SCOPE_PUBLISHER_DISCRIMINATOR]
                    break;
            }
            return toReturn
            
        })
        const iconClassName =(role)=>{
            let cls = 'layout-list-icon '
            switch(role){
                case USER_ROLE_SUPER:
                case USER_ROLE_ADMIN:
                   cls+='admin-icon'
                    break;
                case USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR:
                case USER_ROLE_CONCESSIONAIRE_ADMIN:
                case USER_ROLE_CONCESSIONAIRE_USER:
                   cls+='folder-icon'
                    break;
                case USER_SCOPE_PUBLISHER_DISCRIMINATOR:
                case USER_ROLE_PUBLISHER_ADMIN:
                case USER_ROLE_PUBLISHER_USER:
                   cls+='pub-icon'
                    break;
                case USER_ROLE_ADVERTISER:
                   cls+='adv-icon'
                    break;
            }
            return cls
            
        }

        return{
            roleFilter,
            roles,
            iconClassName,
            selected,
            me
        }

        
    }
}
</script>