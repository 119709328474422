<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel">
                <form class="flex-container flex-col h100" id="panel" @submit.prevent="onSubmit">
                    <div class="panel-header p24">
                        <div class="mini-logo"></div>
                        <h2 class="panel-title nomargin bebas">Crea Nuovo</h2>
                    </div>
                    
                    <div class="flex-container flex-col scrollable-content pl24 pr24 mb24 pb24">
                        <div class="flex-container">    
                            <FileUpload
                                :editing="true"
                                :fileurl ="''"
                                @image-selected="onImageSelected"
                            ></FileUpload>
                            <div class="flex-grow-1 pl16">
                                <label class="flex-container flex-col pb16">
                                    <div class="flex-container heading-and-error">
                                    <h3 class="bebas nomargin">nome</h3>
                                    </div>
                                    <input  
                                        v-model="userscopeName" 
                                        type="text"
                                        @input="onInput"
                                        placeholder="Inserisci il nome"
                                        class="noborder nopadding pl8"
                                    />
                                </label>
                                <label class="flex-container flex-col pb16">
                                    <div class="flex-container heading-and-error">
                                        <h3 class="bebas nomargin">Seleziona Tipologia</h3>
                                    </div>
                                    <select  
                                        name="searchtype" 
                                        id="searchtype" 
                                        class="noborder nopadding" 
                                        @change="typeSelected" 
                                        v-model="type" 
                                    >
                                        <option selected :value="null">Seleziona Tipologia</option>
                                        <option  value="Publisher">Publisher</option>
                                        <option  value="Concessionaire">Concessionaire</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div v-if="type==='Publisher'" class="flex-container flex-col mb24">
                            <AjaxSearchSelect
                                label="Seleziona concessionaria"
                                :feedUrl="ApiEndPoints.USER_SCOPE"
                                :params="{discriminator:USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR}"
                                @select="concessionaireSelected"
                                :selected="concessionaire"
                            >
                            </AjaxSearchSelect>
                            <p>Oppure</p>
                            <label class="flex-container flex-col pb8">
                                <div class="flex-container heading-and-error">
                                    <h3 class="bebas nomargin">Crea nuova concessionaria</h3>
                                </div>
                                <div class="flex-container">
                                    <input  
                                        v-model="newConcessionaireName" 
                                        type="text" 
                                        name="concessionaireName" 
                                        placeholder="Inserisci il nome della concessionaria"
                                        class="noborder nopadding pl8 w100 pr8"
                                    />
                                    <input 
                                        :disabled="newConcessionaireName.length < 3" 
                                        type="submit" 
                                        value="crea" 
                                        class="noborder nopadding bebas pl24 pr24 small-btn" 
                                        @click.prevent="createNewConcessionaire"
                                    >
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="flex-container abs w100 panel-footer">
                        <div class="flex-container">
                            <input type="reset" value="annulla" class="w100 noborder nopadding bebas" @click.prevent="emit('close')">
                            <input type="submit" value="Salva" class="w100 noborder nopadding bebas" :disabled="submitDisabled">
                        </div>
                    </div>
                    
                </form>
                
            </div> 
         </div>
      
    </teleport>
</template>
<script>
import {ref, computed,reactive} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR } from '@/utils/RolesAndPolicies'
import {ApiEndPoints} from '@/api/Endpoints'
import AjaxSearchSelect from '@/components/formelements/AjaxSearchSelect'
import {MESSAGE} from '@/store/VuexPaths'
import FileUpload from '@/components/FileUpload'
export default {
    emits:['close'],
    components:{
        FileUpload,
        AjaxSearchSelect
    },
    setup(_, {emit}){
        const store = useStore()
        const router = useRouter()
        const userscopeName = ref(null)
        const concessionaire = ref(null)
        const newConcessionaireName = ref('')

        const data = reactive({
            name:null,
            discriminator:null,
            concessionaireId:null
        })

        const concessionaires = computed(()=>{
            return store.getters['userscope/concessionaires']
        })
        const type = ref(null)

        const typeSelected = () =>{
           data.discriminator = type.value
        }
        const createNewConcessionaire = async () => {
            
            const response = await store.dispatch('userscope/createUserScope', {
                name: newConcessionaireName, 
                discriminator: USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR
            })
            data.concessionaireId = response.id

        }
        
        const concessionaireSelected =(payload) =>{
            data.concessionaireId = payload.id
        }

        const onInput =()=>{
            data.name= userscopeName.value
        }

        const onSubmit = async () =>{
            
            try {
                const response = await store.dispatch('userscope/createUserScope', { 
                    name:userscopeName, 
                    discriminator:data.discriminator, 
                    concessionaireId:data.concessionaireId 
                })
                if(logo.value){
                    await uploadLogo(response.id)
                }
                
                await store.dispatch(MESSAGE, {
                    title:'Userscope Creato',
                    message:response.name + ' è stato creato correttamente'
                })
                emit('close')
                router.push('/userscope/'+ response.id)
            } catch (error) {
                
                await store.dispatch(MESSAGE, { title:'errore durante la creazione Userscope', message:error.message })
            } 
        }

        const submitDisabled = computed(()=>{
            if(!type.value){
                return true
            }
            if(type.value === 'Concessionaire'){
                return !userscopeName.value || userscopeName.value.length < 2
            } else if(type.value === 'Publisher'){
                return !userscopeName.value || userscopeName.value.length < 2 || !data.concessionaireId
            }
            return true
        })

        const logo = ref(null)
        
        

        const onImageSelected =(files)=>{
            logo.value = files[0]
        }

        const uploadLogo = async(userScopeId) => {
            await store.dispatch('userscope/upload', {
                logo:logo.value, 
                id:userScopeId
            })
          
        }

        return{
            userscopeName,
            emit,
            onSubmit,
            typeSelected,
            type,
            concessionaires,
            concessionaire,
            newConcessionaireName,
            createNewConcessionaire,
            concessionaireSelected,
            data,
            onInput,
            submitDisabled,
            onImageSelected,
            ApiEndPoints,
            USER_SCOPE_CONCESSIONAIRE_DISCRIMINATOR
        }
    }
}
</script>