<template>   
        <ResizableColumnContainer v-if="canManageScopes" :maxCol="4" @total-size="onTotalSize">
            <resizable-column 
                :columnIndex="0" 
                @column-resize="usersColumnResize"
                ref="tools"
                :style="toolStyle"
            >
                <UserScopeTools></UserScopeTools>
                
            </resizable-column>
            <resizable-column 
                :columnIndex="1" 
                @column-resize="usersColumnResize" 
                ref="list" 
                :style="listStyle"
            >
                <UserScopeList 
                    :scopes="scopes"  
                    :selectedId="route.params.id ? parseInt(route.params.id) : 0"
                ></UserScopeList>
            </resizable-column>
            <resizable-column 
                :columnIndex="2" 
                :className="`flex-container flex-col layout-content w100`" 
                @column-resize="usersColumnResize" 
                ref="card" 
                :style="cardStyle"
            >
                <UserScopeCard  v-if="scope"  :key="scope.id" :selectedScope="scope"></UserScopeCard>
                <ColumnPlaceHolder v-else></ColumnPlaceHolder>
            </resizable-column> 
           
        </ResizableColumnContainer>
        <p v-else>
            il ruolo che stai impersonando non può vedere questa pagina
        </p>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import {computed, onMounted, ref} from 'vue'
import {useCapabilities} from '../utils/Capabilities'
import ResizableColumn from '@/components/ResizableColumn'
import ResizableColumnContainer from '@/components/ResizableColumnContainer'
import UserScopeTools from '@/components/userscope/UserScopeTools'
import UserScopeList from '@/components/userscope/UserScopeList'
import UserScopeCard from '@/components/userscope/UserScopeCard'
import ColumnPlaceHolder from '@/components/ColumnPlaceHolder'
import {apiUserScopeFunction} from '@/api/userscopes'
import {
    tools,
    toolStyle,
    card,
    cardStyle,
    list,
    listStyle,
    onTotalSize,
    totalSize,
    usersColumnResize,
} from '@/utils/ColumnsResizer'

export default {
    components:{
        ResizableColumn,
        ResizableColumnContainer,
        UserScopeTools,
        UserScopeList,
        UserScopeCard,
        ColumnPlaceHolder
    },
    setup(){
        const {canManageScopes} = useCapabilities()
        const store = useStore()
        const route = useRoute()
        const scopes = computed(() => {
          return store.getters['userscope/filtered'] 
            ? store.getters['userscope/filtered'] 
            : store.getters['userscope/paged']
        })

       
        const scope = ref(null)    

        onBeforeRouteLeave(async(to)=>{
            const cardWidth = card?.value?.$el ? card.value.$el.getBoundingClientRect().width +'px' : '30%'
            try {
                store.commit('userscope/savecolumnsizes',{
                toolsW:tools.value.$el.getBoundingClientRect().width+'px',
                listW:list.value.$el.getBoundingClientRect().width+'px',
                cardW:cardWidth,
            })
            } catch (error) {
                console.log('could not save columns sizes')
            }
            
            if (to.params.id && to.name !== 'social') {
                scope.value = await apiUserScopeFunction.show(to.params.id)
            }else {
                scope.value = null
            }
        })
        onBeforeRouteUpdate(async (to, from) => {
            if (to.params.id !== from.params.id) {
                scope.value = await apiUserScopeFunction.show(to.params.id)
            }
        })

        onMounted(async()=>{
            if (route?.params?.id) {
                scope.value = await apiUserScopeFunction.show(route.params.id)
            }
        })

        return {
            scopes,
            canManageScopes,
            //selectedScope,
            scope,
            usersColumnResize,
            tools,
            toolStyle,
            list,
            listStyle,
            card,
            cardStyle,
            onTotalSize,
            totalSize,
            route
        }
    }
}
</script>

