<template>
    <li class="layout-list-item">
        <router-link 
            :to="'/userscope/'+ scope.id" 
            :class="selected ? 'layout-list-item-btn flex-container pl16 pr16 selected' : 'layout-list-item-btn flex-container pl16 pr16'"
            
        >
            
            <div 
                v-if="scope.logo" 
                class="layout-list-icon user-icon"
                :style="`background-image:url('${API_BASE +'/file/'+ scope.logo.id}')`"
            >
            </div>
            <div v-else class="layout-list-icon user-icon"></div>

            <div class="flex-container flex-col layout-list-item-details-container">
                <p class="pl8 layout-list-item-title nomargin">{{scope.name}}</p>
                <div class="flex-container">
                    <p class="pl8 layout-list-item-subtitle nomargin f12">
                        <span>{{scope.discriminator !== 'Concessionaire' ? scope.discriminator : 'Concessionaria'}} </span>
                    </p>
                </div>
            </div>
        </router-link>
    </li>
</template>
<script>
import {computed} from 'vue'
import { API_BASE } from '@/api/Endpoints'
export default {
    emits:['list-select'],
    props:{
        scope:{
            type:Object,
            default:()=>{}
        } ,
        selectedId:{
            type:Number,
            default:0
        },
    },
    setup(props,context){
        //const {selectedUser} = useUserFunctions()
        const onSelect=()=>{
            context.emit('list-select', props.scope)
        }
        const selected = computed(()=>{
            return props.selectedId === props.scope.id
        })
        return{
            onSelect,
            selected,
            API_BASE
        }
    }
    
}
</script>