<template>
    <ListHeader
        @input="onInput"
        @change="onSort"
    ></ListHeader>

    <div class="layout-list-content">
        <ul class="layout-list nopadding nomargin">
            <UserScopeListElement 
                v-for="scope in scopes" 
                :key= "scope.id" 
                :scope="scope" 
                :selectedId="selectedId">
            ></UserScopeListElement>
        </ul>
    </div>
    <Pagination
       :pagination="pagination"
       @page-change="onPageChange"
    ></Pagination>
</template>
<script>

import {computed} from 'vue'
import {useStore} from 'vuex'
import UserScopeListElement from '@/components/userscope/UserScopeListElement'
import ListHeader from '@/components/ListHeader'

import Pagination from '@/components/Pagination'
export default {
    props:{
        scopes:{
            type:Array,
            default:()=>[]
        },
        selectedId:{
            type:Number,
            default:null
        }
    },
    components:{
        UserScopeListElement,
        ListHeader,
        Pagination
    },
    setup(){
        const store = useStore()
      
        
        const onInput = (filter)=>{
            store.dispatch('userscope/filter', filter) 
        }
        const onSort =(sorting)=>{        
            store.dispatch('userscope/sort', sorting)
        }
        const onPageChange = async (page)=>{
            await store.dispatch('userscope/userScopes', {PageNumber:page})
        }
        const pagination = computed(()=>{
            return store.getters['userscope/pagination']
        })
        
        return{
           onPageChange,
            onSort,
            onInput,
            pagination
        }
    }
}
</script>